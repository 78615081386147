<template>
  <div class="page">
    <v-list
      :finished="finished"
      @onLoad="onLoad"
      :finishedText="finishedText"
      ref="list"
    >
      <div
        class="item"
        v-for="(item, index) in listData"
        :key="index"
        @click="toDetail(item)"
      >
        <div class="left">
          <img :src="item.picture" />
        </div>
        <div class="right">
          <p>作品名称：{{ item.worksName }}</p>
          <p>创作者姓名：{{ item.framerName }}</p>
          <p>指导老师：{{ item.adviser }}</p>
          <!-- <p>创作日期：{{ dateFormat(item.creationTime) }}</p> -->
        </div>
      </div>
    </v-list>
  </div>
</template>

<script>
import { goodWorksList } from "./api.js";
export default {
  data() {
    return {
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 20,
      },
    };
  },
  methods: {
    dateFormat(str) {
      let date = new Date(str);
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 > 10
          ? date.getMonth() + 1
          : "0" + (date.getMonth() + 1);
      let day = date.getDate() > 10 ? date.getDate() : "0" + date.getDate();
      return year + "-" + month + "-" + day;
    },
    getList() {
      let params = {
        curPage: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
      };
      this.$axios.get(`${goodWorksList}`, { params: params }).then((res) => {
        if (res.code == 200) {
          if (res.data.pages <= res.data.current) {
            this.finished = true;
          } else {
            this.$refs.list._data.loading = false;
          }
          this.finishedText = "没有更多了";
          if (res.data.records.length === 0) {
            this.finishedText = "";
            return;
          }
          res.data.records.forEach((item) => {
            this.listData.push(item);
          });
          this.listData.forEach((ele) => {
            if (ele.picture) {
              ele.picture = this.$handleImg(256, 256, ele.picture);
            }
          });
        }
      });
    },
    onLoad() {
      this.getList();
    },
    toDetail(item) {
      this.$router.push({
        name: "worksOfExcellenceDetail",
        query: {
          workId: item.workId,
        },
      });
    },
  },
};
</script>

<style scoped lang='less'>
.page {
  min-height: 100vh;
  box-sizing: border-box;
  padding: 16px 32px 90px;
  .item {
    width: 100%;
    height: 256px;
    background: #ffffff;
    box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
    border-radius: 16px;
    margin-bottom: 20px;
    overflow: hidden;
    display: flex;
    .right {
      flex: 1;
      padding: 22px 20px 0;
      p {
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-bottom: 12px;
      }
    }
    .left {
      width: 256px;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>